<template>
  <div>
    <big-title>
      Banco<span>.</span>
    </big-title>

    <investor-bank-accounts
      v-if="isMounted"
      :is-moral="isMoral"
      :person-type="investorType"
      :user-id="currentInvestorUserId"
      :has-backoffice-access="hasBackofficeAccess"
      :user-data="base"
    />

  </div>
</template>

<script>
import InvestorBankAccounts from "@views/investor/profile/InvestorBankAccounts";
import investorInfoHelper from "@/helpers/investorInfoHelper";
export default {
  name: "InvestorBank",
  components: {
    InvestorBankAccounts,
  },
  mixins: [investorInfoHelper],
  data(){
    return {
      isMounted: false,
      errorMssg: '',
      successMssg: '',
      investorType: null,
      isMoral: false,
      currentInvestorUserId: null,
      hasBackofficeAccess: null,

      // objeto principal
      base: {},
    }
  },
  async beforeMount() {
    this.showLoading(true);
    this.isMounted = false;
    const requiredObjects = ['user.personal', 'user.business.personal'];
    this.currentInvestorUserId = this.UserId;
    this.base = await this.getInvestorData(this.InvestorId, requiredObjects);
    this.investorType = this.getInvestorType(this.base);
    this.isMoral = this.userIsMoral(this.investorType);
    this.hasBackofficeAccess = this.$acl.check('isBackoffice');
    this.isMounted = true;
    this.showLoading(false);
  },
  computed: {
  },
}
</script>
